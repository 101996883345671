import React from 'react';
import Tooltip from '@mui/material/Tooltip';

import './BulletStatus.scss';
import { Text } from '@cyberpion/cyberpion-ui';

const mapStatus = {
  confirmed: '#4FA686',
  maybe: '#DFB940',
  rejected: '#F46666',
  blocked: '#90949E',
  'seed domain': '#3455DD'
};

const BulletStatus = (props: any) => {
  return (
    <Tooltip
      title={<Text textColor="#FFF">{props.value}</Text>}
      placement="top-start"
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: '#4D4C53',
            color: '#FFFFFF',
            padding: '3px 6px',
            marginBottom: '0px !important',
            '& .MuiTooltip-arrow': {
              color: '#4D4C53'
            },
            border: '1px solid black',
            borderRadius: 1
          }
        }
      }}
    >
      <div
        className="BulletStatus"
        style={{ backgroundColor: mapStatus[props.value?.toLowerCase() as keyof typeof mapStatus] }}
      ></div>
    </Tooltip>
  );
};

export default BulletStatus;
