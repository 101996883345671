import React, { createContext, useContext, useEffect, useState } from 'react';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { setInvestigationRules } from 'features/GridSlice';
// import { RestApi } from 'common/services/rest-api.service';
// import { Common } from 'common/services/common.service';
// import { useSelector } from 'react-redux';
// import { useFlags } from 'launchdarkly-react-client-sdk';

export type DiscoveryScanReportContextType = {
  isLoading: boolean;
  setSelectedRules: (rules: string[]) => void;
  selectedRules: string[];
  setIsLoading: (loading: boolean) => void;
  setSaveCustomViewVisible: (visible: boolean) => void;
  saveCustomViewVisible: boolean;
  setRulesCount: (count: number) => void;
  rulesCount: number;
};

interface DiscoveryScanReportContextProviderProps {
  children: JSX.Element | JSX.Element[];
}

const DiscoveryScanReportContext = createContext<DiscoveryScanReportContextType | null>(null);

export const DiscoveryScanReportContextProvider: React.FC<DiscoveryScanReportContextProviderProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const params = queryString.parse(window.location.search);
  const [selectedRules, setSelectedRules] = useState<string[]>(params.rules ? params.rules.toString().split(',') : []);
  const [saveCustomViewVisible, setSaveCustomViewVisible] = useState<boolean>(false);
  const [rulesCount, setRulesCount] = useState(0);
  // const { investigation_rules } = useSelector((state: any) => state.grid);

  const dispatch = useDispatch<any>();

  useEffect(() => {
    // console.log('XXX: selectedRules', selectedRules);
    dispatch(setInvestigationRules(!!selectedRules.length ? selectedRules.join(',') : ''));
  }, [dispatch, selectedRules]);

  return (
    <DiscoveryScanReportContext.Provider
      value={{
        isLoading,
        setSelectedRules,
        selectedRules,
        setIsLoading,
        saveCustomViewVisible,
        setSaveCustomViewVisible,
        setRulesCount,
        rulesCount
      }}
    >
      {children}
    </DiscoveryScanReportContext.Provider>
  );
};

export const useDiscoveryScanReport = () => {
  return useContext(DiscoveryScanReportContext);
};
