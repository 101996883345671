import { INIT_DATA_LOGIN } from '../config';
import { ITfaForm, IUser } from '../interface';
export const LoginService = {
  returnToLogin(props: ITfaForm) {
    props.setDataLogin(INIT_DATA_LOGIN);
    props.setShowLoginEmail(true);
  },
  //@param: value = string
  //@description: Check the size of a string
  //@return class CSS
  isDisabled(value: string) {
    return value.length < 10 ? 'login_disabled' : '';
  },
  setUser(data: IUser) {
    localStorage.setItem('user_portal', JSON.stringify(data));
    localStorage.setItem('tos_agreement', '1');
  },
  getUser(): IUser {
    let user: any = localStorage.getItem('user_portal');
    return JSON.parse(user);
    // return {
    //     email: "demoportal@cyberpion.com",
    //     first_name: "demo-2",
    //     id: 15,
    //     last_name: "portal-3",
    // }
  },
  removeItem() {
    localStorage.removeItem('user_portal');
  },
  isCyberpionUser(user: IUser) {
    return user.email?.endsWith('@cyberpion.com') || user.email?.endsWith('@ionix.io');
  },
  isViewer(user: IUser) {
    return user.role >= 40;
  },
  isRestrictedMember(user: IUser) {
    return user.role === 35;
  },
  isMember(user: IUser) {
    return user.role >= 30 && user.role < 40;
  },
  isMemberOrBelow(user: IUser) {
    return user.role >= 30;
  },
  isMemberOrAbove(user: IUser) {
    return user.role <= 30;
  },
  isAdmin(user: IUser) {
    return user.role >= 20 && user.role < 30;
  },
  isAdminOrAbove(user: IUser) {
    return user.role < 30;
  },
  isOwner(user: IUser) {
    return user.role < 20;
  }
};
