import React, { useEffect, useState } from 'react';
import { Header } from './Header/Header';
import { Grid } from 'common/components/grid/Grid';
import { AppDispatch, IReduxState } from 'app/store';
import { useDispatch, useSelector } from 'react-redux';
import { removeAllRows, setInitialPath } from 'features/GridSlice';
import { RestApi } from 'common/services/rest-api.service';
import { IRootColumnPicker } from 'common/interfaces/interface';
import { ColumnsManage } from 'common/components/grid/columnsManage';
import { setColumnPicker, setShowColumnPicker } from 'features/columnPickerSlice';
import './DiscoveryInvestigationTool.scss';
import { Common } from 'common/services/common.service';

interface ISettingsItems {
  rows?: any;
  path?: string;
  isMoreButton: boolean;
  isCSVDownloadable: boolean;
  count: number;
  columns: Array<any>;
  selectTab: string;
  rootPath: string;
  identifier: string;
}

const initData = {
  rows: [],
  isMoreButton: true,
  isCSVDownloadable: false,
  count: 0,
  columns: [],
  selectTab: '',
  rootPath: '',
  identifier: ''
};

const DiscoveryInvestigationTool = () => {
  const [data, setData] = useState<ISettingsItems>(initData);
  const { user } = useSelector((state: IReduxState) => state.login);

  const dispatch: AppDispatch = useDispatch();

  const fetchData = () => {
    setData(initData);
    dispatch(removeAllRows());

    const url = Common.AddMsspAccountIfNeeded('discovery/dpbs/scans/columns');

    RestApi.getData(url).subscribe((columns: IRootColumnPicker) => {
      // let fields = ColumnsManage.checkGridColumnsToDisplay(columns.data);
      // console.log('XXX: investigation', fields);
      // dispatch(setFields(fields));
      dispatch(setInitialPath(Common.AddMsspAccountIfNeeded(`discovery/dpbs/${user.company_name}/scans`)));
      dispatch(setColumnPicker(columns.data));
      dispatch(setShowColumnPicker(columns.is_displayable));

      setData({
        rows: [],
        isMoreButton: columns.is_more_button,
        isCSVDownloadable: columns.is_csv_downloadable,
        count: 0,
        selectTab: '',
        identifier: columns.identifier_field,
        columns: ColumnsManage.createColumns(columns.identifier_field, columns.data || [], {
          page: 'settings',
          category: '',
          tab: ''
        }),
        rootPath: `discovery/dpbs/${user.company_name}/scans`
      });
    });
  };

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="DiscoveryInvestigationTool">
      <Header />
      <div className="discovery-wrapper">
        {!!data.columns.length && (
          <div style={{ height: '100%', zIndex: 1 }}>
            <Grid
              columns={data.columns}
              rows={data.rows}
              count={data.count}
              path={data.rootPath}
              isMoreButton={data.isMoreButton}
              isCSVDownloadable={data.isCSVDownloadable}
              identifier={data.identifier}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DiscoveryInvestigationTool;
