import React, { useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { IconMenu } from 'common/components/Icons/IconMenu/IconMenu';
import { clearAllFilters, inputSearch, setFields, sort } from 'features/GridSlice';
import { selectView, setIsEdited } from 'features/ViewsSlice';
import { Common } from 'common/services/common.service';
import { useHistory } from 'react-router-dom';
import { SavedViewOptionsMenu } from 'common/components/grid/components/SavedViews/SavedViewOptionsMenu';
import { EditSavedView } from 'common/components/grid/components/SavedViews/EditSavedView';
import { Text } from '@cyberpion/cyberpion-ui';
// import './SavedViews.scss';

const SavedViews = (props: any) => {
  const [showMenu, setShowMenu] = useState<string | boolean>(false);
  const { viewSelected, genericViews, defaultViews, userCreatedViews, editModeId } = useSelector(
    (state: any) => state.views
  );
  const dispatch = useDispatch<any>();
  const history = useHistory();

  const viewOnClick = (view: any, index: number) => {
    if (viewSelected === view.id) {
      return;
    }

    dispatch(sort(''));
    dispatch(clearAllFilters());
    dispatch(inputSearch(''));
    dispatch(setFields([]));
    // dispatch(setGlobalFilter(''));
    dispatch(selectView(view.id));
    history.push(Common.getDecodedViewPath(view.path));
    dispatch(setIsEdited(false));
  };

  const getItemHTML = (view: any, index: number, type: 'user' | 'generic') => {
    const isDefault = defaultViews[props.page] && defaultViews[props.page][props.tab]?.views[0].id === view.id;
    return (
      <div
        key={type + index}
        onClick={() => viewOnClick(view, index)}
        className={classNames('view-item-wrapper', { selected: viewSelected === view.id })}
      >
        <div className="view-content-wrapper">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className="icon">
              {isDefault && <img className="default-icon" src={`/assets/images/star-yellow.svg`} alt="Default" />}
              {type === 'generic' && <img className="generic-icon" src={`/assets/images/logo-ionix.svg`} alt="Ionix" />}
              {type === 'user' && <img className="user-icon" src={`/assets/images/user.svg`} alt="User" />}
            </div>
            <div>
              {type === 'user' && editModeId === view.id ? <EditSavedView view={view} /> : <Text>{view.label}</Text>}
            </div>
          </div>
          <div style={{ flexGrow: 1 }} />
          <div
            className="menu"
            onClick={e => {
              e.stopPropagation();
              setShowMenu(view.id);
            }}
          >
            <IconMenu active={false} />
            {showMenu === view.id && (
              <SavedViewOptionsMenu
                type={type}
                isDefault={isDefault}
                view={view}
                onClose={() => {
                  setShowMenu(false);
                }}
              />
            )}
          </div>
        </div>
        {/* {viewSelected === index && isEdited(view.path) && (
          <div className="buttons">
            {viewSelected === index && isEdited(view.path) && <div className="edited-badge">Edited</div>}
            <span className="reset" onClick={resetOnClick(view)}>
              Reset
            </span>
          </div>
        )} */}
      </div>
    );
  };

  return (
    <div className="DiscoverySavedViews">
      <div className={classNames('views-list')}>
        {userCreatedViews[props.page] &&
          userCreatedViews[props.page][props.tab]?.views.map((view: any, index: number) => {
            return getItemHTML(view, index, 'user');
          })}
        {genericViews[props.page] &&
          genericViews[props.page][props.tab]?.views.map((view: any, index: number) => {
            return getItemHTML(view, index, 'generic');
          })}
      </div>
    </div>
  );
};

export default SavedViews;
