import { Common, updateHeader } from 'common/services/common.service';
import { useEffect, useState } from 'react';
import { SidePanel } from './SidePanel/SidePanel';
import { Grid } from 'common/components/grid/Grid';
import { RestApi } from 'common/services/rest-api.service';
import { IRootColumnPicker } from 'common/interfaces/interface';
import { AppDispatch, IReduxState } from 'app/store';
import { useDispatch, useSelector } from 'react-redux';
import { ColumnsManage } from 'common/components/grid/columnsManage';
import {
  removeAllRows,
  resetQueryString,
  setConfirmationParams,
  setDeletePromptParams,
  setFields,
  setInitialPath,
  setAddFiltersGridRows
} from 'features/GridSlice';
import { setSubsidiarySelectedId, setSubsidiariesEditMode, toggleSubsidiariesPopup } from 'features/SubsidiariesSlice';
import { setMetaGroupSelectedId, setMetaGroupEditMode, toggleMetaGroupPopup } from 'features/GroupsSlice';
import { UserManagementActions } from './UserManagement/UserManagementActions/UserManagementActions';
import { EmailDomainsActions } from './EmailDomains/EmailDomainsActions/EmailDomainsActions';
import { IpRestrictionsActions } from './IpRestrictions/IpRestrictionsActions/IpRestrictionsActions';
import { FilterGroupsActions } from './FilterGroups/FilterGroupsActions/FilterGroupsActions';
import { ManageAssetsActions } from './ManageAssets/ManageAssetsActions/ManageAssetsActions';
import { SubsidiariesActions } from './Subsidiaries/SubsidiariesActions/SubsidiariesActions';
import MetaGroupsActions from './MetaGroups/MetaGroupsActions';
import { PopUp } from 'common/components/popUp/PopUp';
import { DeletePromptPopup } from './DeletePromptPopup/DeletePromptPopup';
import { APITokensActions } from './APITokens/APITokensActions/APITokensActions';
import { IpRangesActions } from './IpRanges/IpRangesActions/IpRangesActions';
import { CustomPortsActions } from './CustomPorts/CustomPortsActions/CustomPortsActions';
import { ConfirmationPopup } from './ConfirmationPopup/ConfirmationPopup';
import { Menu } from './SidePanel/config';
import { CustomSettingsPages } from './CustomSettingsPages';
import { PathNames } from 'common/constants/pathNames';
import { Paths } from 'common/constants/paths';
import { setColumnPicker, setShowColumnPicker } from 'features/columnPickerSlice';

import './Setting.scss';
import { Redirect } from 'react-router-dom';

export interface ISettingsItems {
  rows?: any;
  path?: string;
  // columnPicker: IColumnPicker[];
  // showColumnPicker: boolean;
  isMoreButton: boolean;
  isCSVDownloadable: boolean;
  count: number;
  columns: Array<any>;
  selectTab: string;
  rootPath: string;
  identifier: string;
}

// interface INotificationItems {
//   rows: any;
// }

export function Settings(props: any) {
  const dispatch: AppDispatch = useDispatch();
  const [data, setData] = useState<ISettingsItems>(initData());
  const { user } = useSelector((state: IReduxState) => state.login);
  // const [notificationsData, setNotificationsData] = useState<object[] | null>(
  //   null
  // );
  const [tab, setTab] = useState<string>(props.match.params.tab || '');
  const [category, setCategory] = useState<string>(props.match.params.category);
  const { deletePromptParams, confirmationParams } = useSelector((state: IReduxState) => state.grid);
  const { columnPicker } = useSelector((state: IReduxState) => state.columnPicker);
  const [pageType, setPageType] = useState<string>(getPageType());

  const getColumnsPath = () => {
    if (tab) {
      return `settings/${category}/${tab}/columns/`;
    }
    return `settings/${category}/columns/`;
  };

  const getRowsPath = () => {
    if (tab) {
      return `settings/${category}/${tab}/`;
    }
    return `settings/${category}/`;
  };

  const prepareHeaderText = () => {
    const components = ['Settings'];
    const _category = Common.capitalizeFirstLetterOfEachWord(category);
    const _tab = Common.capitalizeFirstLetterOfEachWord(tab);
    if (_category) {
      components.push(_category);
    }
    if (_tab) {
      components.push(_tab);
    }
    let text = components.join(' / ');
    return text;
  };

  const getDeletePath = () => {
    const _tab = props.match.params.tab;
    const _category = props.match.params.category;

    switch (_tab || _category) {
      case 'subsidiaries':
        return Paths[PathNames.subsidiaries];
      case 'meta-groups':
        return Paths[PathNames.metaGroups];
      default:
        return '';
    }
  };
  const handleMenuItemClick = (rowData: any, option: any, type: string, event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (option.id === 'delete') {
      dispatch(type === 'subsidiaries' ? setSubsidiarySelectedId(rowData.id) : setMetaGroupSelectedId(rowData.id));

      dispatch(
        setDeletePromptParams({
          path: getDeletePath(),
          index: null,
          ids: [rowData.id],
          message: `Are you sure you would like to remove this ${
            type === 'subsidiaries' ? 'subsidiariy' : 'meta group'
          }?`
        })
      );
    }
    if (option.id === 'edit') {
      if (type === 'subsidiaries') {
        dispatch(setSubsidiariesEditMode(true));
        dispatch(setSubsidiarySelectedId(rowData.id));
        dispatch(toggleSubsidiariesPopup(true));
      } else if (type === 'metaGroups') {
        dispatch(setMetaGroupEditMode(true));
        dispatch(setMetaGroupSelectedId(rowData.id));
        dispatch(toggleMetaGroupPopup(true));
      }
    }
  };

  const getData = () => {
    setData(initData());
    dispatch(removeAllRows());

    RestApi.getData(getColumnsPath()).subscribe((columns: IRootColumnPicker) => {
      let fields = ColumnsManage.checkGridColumnsToDisplay(columns.data);
      dispatch(setFields(fields));
      dispatch(setInitialPath(getRowsPath()));

      // IN CASE CUSTOM_GROUPS, ADDING FILTER type__in=1 (the API returns groups + subsidiaries, \
      // need to get only the groups without subsidiaries)
      if (props.match.params.category === 'groups') {
        dispatch(setAddFiltersGridRows('type__in=1'));
      }
      dispatch(setColumnPicker(columns.data));
      dispatch(setShowColumnPicker(columns.is_displayable));

      setData({
        rows: [],
        // columnPicker: columns.data || [],
        // showColumnPicker: columns.is_displayable,
        isMoreButton: false,
        isCSVDownloadable: columns.is_csv_downloadable,
        count: 0,
        selectTab: '',
        identifier: columns.identifier_field,
        columns: ColumnsManage.createColumns(
          columns.identifier_field,
          columns.data || [],
          {
            page: 'settings',
            category: category,
            tab: tab
          },
          { menuItemOnClick: handleMenuItemClick }
        ),
        rootPath: getRowsPath()
      });
    });
  };

  // const getNotificationsData = () => {
  //   setNotificationsData(mock);
  // };

  useEffect(() => {
    setTab(props.match.params.tab || '');
    setCategory(props.match.params.category);
    setPageType(getPageType());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params]);

  useEffect(() => {
    updateHeader.sendHeaderText(prepareHeaderText());
    dispatch(setInitialPath(removeAllRows()));
    if (pageType === 'grid') {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, category]);

  useEffect(() => {
    dispatch(resetQueryString());
    return () => {
      dispatch(removeAllRows());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, category]);

  useEffect(() => {
    dispatch(resetQueryString());
    return () => {
      dispatch(removeAllRows());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getPageType(): string {
    const pathname = window.location.pathname;
    let type: string = '';
    for (let item in Menu) {
      if (Menu[item].path) {
        if (Menu[item].path === pathname) {
          type = Menu[item].type;
        }
      } else {
        for (let subitem in Menu[item]) {
          if (Menu[item][subitem].path === pathname) {
            type = Menu[item][subitem].type;
          }
        }
      }
    }
    return type;
  }

  function initData(): ISettingsItems {
    return {
      rows: [],
      // columnPicker: [],
      // showColumnPicker: false,
      isMoreButton: true,
      isCSVDownloadable: false,
      count: 0,
      columns: [],
      selectTab: '',
      rootPath: '',
      identifier: ''
    };
  }

  // const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  //   event.stopPropagation();
  // };

  // const handleMenuItemClick = (option: any) => (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(null);
  //   if (option.id === 'delete') {
  //     setShowDeletePopup(true);
  //   }
  //   if (option.id === 'edit') {
  //     onNotificationItemMenuClick(notification, 'edit');
  //   }
  // };

  const getActionsComponent = (_props: any) => {
    const _tab = props.match.params.tab;
    const _category = props.match.params.category;
    const hasActions = columnPicker.filter((d: any) => d.name === 'actions').length;
    if (!hasActions) {
      return <div></div>;
    }

    switch (_tab || _category) {
      case 'user-management':
        return <UserManagementActions {..._props} />;
      case 'ip-restrictions':
        return <IpRestrictionsActions {..._props} />;
      case 'domain-access-restrictions':
        return <EmailDomainsActions {..._props} />;
      case 'groups':
        return <FilterGroupsActions {..._props} />;
      case 'meta-groups':
        return <MetaGroupsActions {..._props} />;
      case 'manage-fqdns':
        return <ManageAssetsActions {..._props} />;
      case 'subsidiaries':
        return <SubsidiariesActions {..._props} />;
      case 'tokens':
        return <APITokensActions {..._props} />;
      case 'ip-ranges':
        return <IpRangesActions {..._props} />;
      case 'custom-ports':
        return <CustomPortsActions {..._props} />;
      default:
        return <div></div>;
    }
  };

  const showDeletePopup = !!deletePromptParams.category || !!deletePromptParams.path;
  const extraProps = {
    ...(!!deletePromptParams.path ? { path: deletePromptParams.path } : {})
  };
  return (
    <div className="Settings main-content-area">
      {user.is_mssp_user && !Common.getParamFromUrl('account') && <Redirect to="/pages/settings/tokens/" />}
      <SidePanel />
      <PopUp visible={showDeletePopup} setVisible={() => dispatch(setDeletePromptParams({}))}>
        <DeletePromptPopup onClose={() => dispatch(setDeletePromptParams({}))} {...extraProps} />
      </PopUp>
      <PopUp visible={!!confirmationParams.visible} setVisible={() => dispatch(setConfirmationParams({}))}>
        <ConfirmationPopup onClose={() => dispatch(setConfirmationParams({}))} />
      </PopUp>
      <div className="settings-wrapper">
        {pageType === 'grid' && !!data.columns.length && (
          <div style={{ height: '100%', zIndex: 1 }}>
            <Grid
              columns={data.columns}
              rows={data.rows}
              count={data.count}
              path={data.rootPath}
              // columnPicker={data.columnPicker}
              // showColumnPicker={data.showColumnPicker}
              isMoreButton={data.isMoreButton}
              isCSVDownloadable={data.isCSVDownloadable}
              addedActions={(props: any) => getActionsComponent(props)}
              identifier={data.identifier}
              initialState={{
                pinnedColumns: { right: ['actions'] }
              }}
            />
          </div>
        )}
        {pageType === 'custom' && <CustomSettingsPages page={category} />}
      </div>
      {/* <SettingsRouting /> */}
    </div>
  );
}
