import { useEffect, useState } from 'react';
import { Popover } from '@mui/material';
import { Text } from '@cyberpion/cyberpion-ui';
import { useDispatch, useSelector } from 'react-redux';
import { IReduxState } from 'app/store';
import { makeStyles } from '@material-ui/core/styles';
import { IconArrow } from 'common/components/Icons/IconArrow/IconArrow';
import classNames from 'classnames';
// import { RestApi } from 'common/services/rest-api.service';
// import { showSuccess } from 'features/AlertSlice';
// import { Common } from 'common/services/common.service';
import { setDiscoveryVerdictDropdown, setShowAddCommentPopupDiscoveryPending, updateRows } from 'features/GridSlice';
// import { setCommentsTabCount } from 'features/popSideSlice';
import { showSuccess } from 'features/AlertSlice';
// import { RestApi } from 'common/services/rest-api.service';
// import { Common } from 'common/services/common.service';
import './DiscoveryVerdictDropdown.scss';

const useStyles = makeStyles({
  popoverRoot: {
    marginTop: '10px',
    maxHeight: 400,
    minHeight: 105,
    width: '110px !important',
    padding: '6px',
    border: '1px solid #ecedff',
    '& .option-item': {
      color: '#5D6576',
      padding: '8px 10px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        backgroundColor: '#f6f8ff'
      },
      '&.selected': {
        backgroundColor: '#f6f8ff80 !important'
      },
      '& .cyberpion-ui-text': {
        borderBottom: 'none'
      }
    }
  }
});

const colors = {
  Select: '#FFF',
  Approved: '#50A686',
  Maybe: '#DFB941',
  Rejected: '#F46666',
  Pending: '#B7BBC5'
};

const DiscoveryVerdictDropdown = (props: any) => {
  const [optionSelected, setOptionSelected] = useState<any>({ label: 'Select', name: 'select', is_toggled: false });
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { columnPicker } = useSelector((state: IReduxState) => state.columnPicker);
  const { rows } = useSelector((state: IReduxState) => state.grid);
  // const { user } = useSelector((state: IReduxState) => state.login);
  // const { commentsTabCount } = useSelector((state: IReduxState) => state.popSide);
  const dispatch = useDispatch<any>();

  const classes = useStyles();

  const options = !!columnPicker?.length
    ? columnPicker
        ?.find((col: any) => col.type === 'discovery_investigation_asset_verdict')
        ?.choices.map((item: any) => ({ label: item.label, name: item.name, is_toggled: item.is_toggled }))
    : [];
  // if (!!options && options.length) {
  //   options = [{ label: 'Select', name: 'select', is_toggled: false }, ...options];
  // }

  useEffect(() => {
    if (!!props.row?.verdict) {
      const dropdown_with_action = columnPicker.find(
        (col: any) => col.type === 'discovery_investigation_asset_verdict'
      );
      if (dropdown_with_action) {
        let item = dropdown_with_action.choices?.find((item: any) => item.label === props.row.verdict);
        if (!item) {
          item = { label: 'Select', name: 'select', is_toggled: false };
        }
        setOptionSelected(item);
      }
    }
  }, [columnPicker, props.row.verdict]);

  useEffect(() => {
    if (!!rows && !!rows.length) {
      const row = rows.find((row: any) => row.id === props.row.id);
      if (!!row?.verdict) {
        let item = columnPicker
          .find((item: any) => item.type === 'discovery_investigation_asset_verdict')
          ?.choices.find((item: any) => item.label === row.verdict);
        if (!item) {
          item = { label: 'Select', name: 'select', is_toggled: false };
        }
        setOptionSelected(item);
      }
    }
  }, [rows, props.row.id, columnPicker]);

  const handleOnClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleItemOnClick = (option: any) => (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setOptionSelected(option);
    setAnchorEl(null);
    if (option.label !== 'Pending') {
      // const url = Common.AddMsspAccountIfNeeded('remediation/action-items/state/');
      // const payload = {
      //   ids: [props.row.id],
      //   state: option.name
      // };
      // RestApi.setData(url, payload, 'POST').subscribe(
      //   (response: any) => {
      //     setAnchorEl(null);
      //     setOptionSelected(option);

      //     const row = rows.find((row: any) => row.id === props.row.id);
      //     const updatedRow = [
      //       {
      //         ...row,
      //        verdict: option.label
      //       }
      //     ];
      //     dispatch(updateRows({ rows: updatedRow, identifier: 'id' }));
      //     dispatch(showSuccess('Status Updated Successfully'));
      //   },
      //   error => {
      //     setAnchorEl(null);
      //   }
      // );

      //     setAnchorEl(null);
      //     setOptionSelected(option);

      const row = rows.find((row: any) => row.id === props.row.id);
      const updatedRow = [
        {
          ...row,
          verdict: option.label
        }
      ];
      dispatch(updateRows({ rows: updatedRow, identifier: 'id' }));
      dispatch(showSuccess('Updated Successfully'));
    } else {
      setAnchorEl(null);
      dispatch(
        setDiscoveryVerdictDropdown({
          listLastStatusOption: { [props.row.id]: optionSelected },
          nextStatusOption: option
        })
      );

      const row = rows.find((row: any) => row.id === props.row.id);
      const updatedRow = [
        {
          ...row,
          verdict: 'Pending'
        }
      ];
      dispatch(updateRows({ rows: updatedRow, identifier: 'id' }));
      setOptionSelected(option);
      dispatch(setShowAddCommentPopupDiscoveryPending(true));
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <div className="DiscoveryVerdictDropdown">
      <div className="dropdown" onClick={handleOnClick}>
        <div
          style={{
            borderRadius: '50%',
            backgroundColor: colors[optionSelected.label as keyof typeof colors],
            width: 8,
            height: 8,
            marginRight: 8,
            border: optionSelected.name === 'select' ? '1px dashed #B7BBC5' : 'none'
          }}
        ></div>
        <div>{optionSelected?.label}</div>

        <div className="chevron-icon">
          <IconArrow toggleRotate={open} />
        </div>
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        classes={{ paper: classes.popoverRoot }}
      >
        {!!options?.length &&
          options.map((option: any) => (
            <div
              key={option.name}
              className={classNames('option-item', { selected: option.label === optionSelected?.label })}
              onClick={handleItemOnClick(option)}
            >
              <div
                style={{
                  borderRadius: '50%',
                  backgroundColor: colors[option.label as keyof typeof colors],
                  width: 8,
                  height: 8,
                  marginRight: 10
                }}
              ></div>
              <Text textColor="#5D6576" textSize={13}>
                {option.label}
              </Text>
            </div>
          ))}
      </Popover>
    </div>
  );
};

export default DiscoveryVerdictDropdown;
