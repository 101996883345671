import * as EmailValidator from 'email-validator';
import { InputField } from 'common/components/InputField/InputField';
import { Button } from 'common/components/Button/Button';
import classNames from 'classnames';
import { RestApi } from 'common/services/rest-api.service';
import { Common } from 'common/services/common.service';
import { INIT_DATA_LOGIN } from 'login/config';
import { useEffect, useState } from 'react';
import { ILogin } from 'login/interface';
import _ from 'lodash';
import { SSO } from 'login/components/SSO/SSO';
import { LoginService } from 'login/services/login.service';
import { pathManageService } from 'login/services/pathManage.service';
import { useDispatch } from 'react-redux';
import { checkSession } from 'features/LoginSlice';
import { useHistory } from 'react-router-dom';
import { Text } from '@cyberpion/cyberpion-ui';
import './LoginForm.scss';
import { TOSAgreement } from './TosAgreement';

const ERRORS = {
  EMAIL_INVALID: 'Invalid Input',
  WRONG_PASSWORD: 'Invalid Input',
  GENERAL: 'Something went wrong',
  VALIDATION_INVALID: 'Invalid Input'
};

const MIN_PASSWORD_LENGTH = 10;
const VALIDATION_CODE_LENGTH = 6;

export function LoginForm(props: any) {
  const dispatch = useDispatch<any>();
  let history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);

  const [generalError, setGeneralError] = useState<string>('');

  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');

  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [showPasswordField, setShowPasswordField] = useState<boolean>(false);
  const [showLoginButton, setShowLoginButton] = useState<boolean>(true);

  const [validationCode, setValidationCode] = useState<string>('');
  const [validationCodeError, setValidationCodeError] = useState<string>('');
  const [showValidationField, setShowValidationField] = useState<boolean>(false);
  const [isRequiredValidationCode, setIsRequiredValidationCode] = useState<boolean>(false);

  const [buttonText, setButtonText] = useState<string>('Next');
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

  const [loginData, setLoginData] = useState<ILogin>(Common.cloneData(INIT_DATA_LOGIN));
  const [accountMethodsType, setAccountMethodsType] = useState<string[]>([]);
  const [userMethodsType, setUserMethodsType] = useState<string[]>([]);
  const [showMethodsButtons, setShowMethodsButtons] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState<any>(null);

  useEffect(() => {
    setIsButtonDisabled(!EmailValidator.validate(email));
  }, [email]);

  useEffect(() => {
    setIsButtonDisabled(password.length < MIN_PASSWORD_LENGTH);
  }, [password]);

  useEffect(() => {
    setIsButtonDisabled(validationCode.length < VALIDATION_CODE_LENGTH);
  }, [validationCode]);

  const clearErrors = () => {
    setGeneralError('');
    setEmailError('');
    setValidationCodeError('');
    setPasswordError('');
  };

  const login = (method?: string) => {
    let url = `auth/login/`;
    let data: any = {
      email: email,
      password: password
    };

    if (isRequiredValidationCode) {
      data.otp_method = method?.toLowerCase() || selectedMethod;
      if (!!method) setSelectedMethod(method?.toLowerCase());
    }
    if (validationCode) {
      data.otp_token = validationCode;
    }

    RestApi.setData(url, data, 'POST').subscribe(
      (response: any) => {
        if (response && response.id) {
          LoginService.setUser(response);
          dispatch(checkSession());
          if (response.is_mssp_user) {
            history.push('/pages/accounts');
          } else {
            const next = pathManageService.nextPath();
            if (next.includes('http') && window.location.hostname.includes(next)) {
              // in case the redirect is to an external site
              window.location.href = decodeURIComponent(next);
              return;
            } else {
              history.push(next);
            }
          }
        } else {
          setIsButtonDisabled(true);
          setShowMethodsButtons(false);
          setShowValidationField(true);
          setButtonText('Login');
          setLoading(false);
        }
      },
      error => {
        setLoading(false);
        if (isRequiredValidationCode && buttonText !== 'Login') {
          setShowMethodsButtons(true);
        }
        if (error.status === 401) {
          if (!validationCode) {
            setPasswordError(ERRORS.WRONG_PASSWORD);
          } else {
            setValidationCodeError(ERRORS.VALIDATION_INVALID);
          }
        } else if (error.status === 403) {
          setGeneralError(error.message);
        } else {
          setGeneralError(ERRORS.GENERAL);
        }
      }
    );
  };

  const handleSubmit = (method?: string) => (e: any) => {
    e.preventDefault();
    setLoading(true);
    clearErrors();
    if (password) {
      login(method);
      return;
    }
    if (EmailValidator.validate(email)) {
      setLoading(true);
      let url = `auth/login_methods/`;
      RestApi.setData(url, { email: email }, 'POST').subscribe(
        (response: any) => {
          let merge = _.merge(Object.assign({}, loginData), response);
          setLoginData(merge);
          setLoading(false);
          setIsButtonDisabled(true);
          setShowPasswordField(!!merge.jwt.is_active);
          setShowLoginButton(!!merge.jwt.is_active);
          if (merge.jwt.two_fa.is_active) {
            setIsRequiredValidationCode(true);
            setAccountMethodsType(merge.jwt.two_fa.options);
            setUserMethodsType(merge.jwt.two_fa.selected);
            setShowMethodsButtons(true);
            // setButtonText('Send Validation Code');
          } else {
            setButtonText('Login');
          }
        },
        error => {
          setEmailError(ERRORS.EMAIL_INVALID);
          setLoading(false);
        }
      );
    } else {
      setLoading(false);
      setEmailError(ERRORS.EMAIL_INVALID);
    }
  };

  const onChangeEmail = (value: string) => {
    setEmail(value);
    setEmailError('');
  };

  const onChangePassword = (value: string) => {
    setPassword(value);
    setPasswordError('');
  };

  const onChangeValidation = (value: string) => {
    setValidationCode(value);
    setValidationCodeError('');
  };

  return (
    <form className={classNames('LoginForm', { visible: props.visible })}>
      <h6>Login to your account</h6>
      <InputField
        type="email"
        placeholder="Email"
        onChange={onChangeEmail}
        error={emailError}
        disabled={showPasswordField}
      />
      {showPasswordField && (
        <InputField type="password" placeholder="Password" onChange={onChangePassword} error={passwordError} />
      )}
      {showValidationField && (
        <InputField
          type="text"
          placeholder="Validation Code"
          onChange={onChangeValidation}
          error={validationCodeError}
        />
      )}
      {!!(showPasswordField || showValidationField) && <TOSAgreement />}
      {showLoginButton && !showMethodsButtons && (
        <Button
          size="big"
          disabled={isButtonDisabled}
          text={buttonText}
          buttonStyle="main"
          type="submit"
          loading={loading}
          onClick={handleSubmit()}
        />
      )}
      {isRequiredValidationCode && showMethodsButtons && (
        <>
          <Text style={{ marginBottom: 10 }}>Choose Validation Method</Text>
          <div
            style={{ width: '100%' }}
            className={classNames(
              { 'two-buttons': accountMethodsType.length > 1 },
              { 'small-padding-bottom': loginData.sso.is_active }
            )}
          >
            {accountMethodsType.map((method: string, index: number) => (
              <div style={index !== 0 ? { display: 'flex' } : {}}>
                <div
                  className={classNames(
                    { 'small-padding-right': index === 0 && accountMethodsType.length > 1 },
                    { 'small-padding-left': index !== 0 }
                  )}
                >
                  <Button
                    key={index}
                    size="big"
                    dataTestId={`login-button-${method}`}
                    disabled={isButtonDisabled || !userMethodsType.includes(method)}
                    text={`${method === 'email' ? Common.capitalizeFirstLetter(method) : method.toUpperCase()}`}
                    buttonStyle="main"
                    type="submit"
                    loading={loading}
                    onClick={handleSubmit(method)}
                  />
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      {loginData.sso.is_active && <SSO list={loginData.sso.options} samlIdp={loginData.sso.saml_idp} />}
      {generalError && <div className="general-error">{generalError}</div>}
    </form>
  );
}
