
import './PercentageRenderer.scss';

export function PercentageRenderer(props: any) {
  if (!props.value) {
    return null;
  }
  return (
    <div className="percentage-renderer">
        {(props.value*100).toFixed(2)}%
    </div>
  );
}
