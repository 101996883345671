import { PathNames } from 'common/constants/pathNames';
import { Paths } from 'common/constants/paths';

interface IMenu {
  [key: string]: any;
}

export const Menu: IMenu = {
  'Users & Permissions': {
    'User Management': {
      path: `/pages/${Paths[PathNames.userManagement]}`,
      viewPermissionLevel: 'admin',
      editPermissionLevel: 'admin',
      type: 'grid',
      showForMssp: false
    },
    'Domain Restrictions': {
      path: `/pages/${Paths[PathNames.domainRestriction]}`,
      viewPermissionLevel: 'admin',
      editPermissionLevel: 'admin',
      type: 'grid',
      showForMssp: false
    },
    'IP Restrictions': {
      path: `/pages/${Paths[PathNames.ipRestriction]}`,
      viewPermissionLevel: 'admin',
      editPermissionLevel: 'admin',
      type: 'grid',
      showForMssp: false
    }
  },
  'Organization Management': {
    FQDNs: {
      path: `/pages/${Paths[PathNames.manageFqdns]}`,
      viewPermissionLevel: 'member',
      editPermissionLevel: 'admin',
      type: 'grid',
      showForMssp: false
    },
    'IP Ranges': {
      path: `/pages/${Paths[PathNames.ipRanges]}`,
      viewPermissionLevel: 'member',
      editPermissionLevel: 'admin',
      type: 'grid',
      showForMssp: false
    },
    'Seed Data': {
      path: `/pages/${Paths[PathNames.seedData]}`,
      viewPermissionLevel: 'admin',
      editPermissionLevel: 'admin',
      type: 'custom',
      showForMssp: false,
      ionixBEFlag: 'is_show_seed_data'
    },
    'Investigation Tool': {
      path: `/pages/${Paths[PathNames.investigationTool]}`,
      viewPermissionLevel: 'admin',
      editPermissionLevel: 'admin',
      type: 'custom',
      showForMssp: false,
      ionixBEFlag: 'is_show_seed_data'
    }
  },
  'Groups & Subsidiaries': {
    Subsidiaries: {
      path: `/pages/${Paths[PathNames.subsidiaries]}`,
      viewPermissionLevel: 'member',
      editPermissionLevel: 'admin',
      type: 'grid',
      showForMssp: false
    },
    'Custom Groups': {
      path: `/pages/${Paths[PathNames.groups]}`,
      viewPermissionLevel: 'member',
      editPermissionLevel: 'admin',
      type: 'grid',
      showForMssp: false
    },
    'Meta Groups': {
      path: `/pages/${Paths[PathNames.metaGroups]}`,
      viewPermissionLevel: 'member',
      editPermissionLevel: 'admin',
      type: 'grid',
      showForMssp: false
    }
  },
  'Integrations & Adapters': {
    path: `/pages/${Paths[PathNames.integrations]}`,
    viewPermissionLevel: 'admin',
    editPermissionLevel: 'admin',
    type: 'custom',
    featureFlag: 'isIntegrations',
    showForMssp: false
  },
  Notifications: {
    path: `/pages/${Paths[PathNames.notifications]}`,
    viewPermissionLevel: 'admin',
    editPermissionLevel: 'admin',
    type: 'custom',
    featureFlag: 'isNotifications',
    showForMssp: false
  },
  'Audit Logs': {
    path: `/pages/${Paths[PathNames.auditLogs]}`,
    viewPermissionLevel: 'member',
    editPermissionLevel: 'member',
    type: 'grid',
    showForMssp: false
  },
  API: {
    path: `/pages/${Paths[PathNames.tokens]}`,
    viewPermissionLevel: 'member',
    editPermissionLevel: 'admin',
    type: 'grid',
    showForMssp: true
  },
  Ports: {
    path: `/pages/${Paths[PathNames.customPort]}`,
    viewPermissionLevel: 'member',
    editPermissionLevel: 'admin',
    type: 'grid',
    showForMssp: false
  }
};
