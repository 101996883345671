import React, { useEffect, useState } from 'react';
import { AppDispatch, IReduxState } from 'app/store';
import { useDispatch, useSelector } from 'react-redux';
import { Text, Button } from '@cyberpion/cyberpion-ui';
import classNames from 'classnames';
import { Popover } from '@mui/material';
import { IRootColumnPicker } from 'common/interfaces/interface';
import { RestApi } from 'common/services/rest-api.service';
import {
  setDiscoveryVerdictDropdown,
  setFields,
  setInitialPath,
  setShowAddCommentPopupDiscoveryPending,
  updateRows
} from 'features/GridSlice';
import { ISettingsItems } from 'pages/Settings/Settings';
import { setColumnPicker, setShowColumnPicker } from 'features/columnPickerSlice';
import { Grid } from 'common/components/grid/Grid';
import { Common } from 'common/services/common.service';
import InvestigationLeftPanel from './InvestigationLeftPanel/InvestigationLeftPanel';
import { ColumnsManage } from 'common/components/grid/columnsManage';
import {
  DiscoveryScanReportContextProvider,
  DiscoveryScanReportContextType,
  useDiscoveryScanReport
} from './DiscoveryScanReportContext';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { SaveCustomView } from 'common/components/grid/components/SavedViews/SaveCustomView';
import { PopUp } from 'common/components/popUp/PopUp';
import AddDiscoveryCommentPopup from 'common/components/AddDiscoveryCommentPopup/AddDiscoveryCommentPopup';

import './DiscoveryScanReport.scss';
import { showSuccess } from 'features/AlertSlice';
import { useComponentVisible } from 'common/hooks/useComponentVisible';

const iconCompleted = (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <circle cx="6" cy="6.00391" r="6" fill="#4FA686" />
    <path d="M8 5.00391L5.5625 8.00391L4 6.71819" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);
const iconInProgress = (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <circle cx="6" cy="6.00391" r="6" fill="#DFB940" />
    <path
      d="M6 2.00391C7.06087 2.00391 8.07828 2.42533 8.82843 3.17548C9.57857 3.92562 10 4.94304 10 6.00391C10 7.06477 9.57857 8.08219 8.82843 8.83233C8.07828 9.58248 7.06087 10.0039 6 10.0039L6 6.00391L6 2.00391Z"
      fill="white"
    />
  </svg>
);
const iconQueued = (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <circle cx="6" cy="6.00391" r="5.25" stroke="#5D6576" stroke-width="1.5" stroke-dasharray="2 2" />
  </svg>
);
const iconFailed = (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="6" cy="6" r="6" fill="#5D6576" />
    <path d="M8.25 3.75L3.75 8.25" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M8.25 8.25L3.75 3.75" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

const initData = {
  rows: [],
  isMoreButton: true,
  isCSVDownloadable: false,
  count: 0,
  columns: [],
  selectTab: '',
  rootPath: '',
  identifier: ''
};

const optionsTime: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
  hour12: true
};

const options = [
  { label: 'Approved', name: 'approved', color: '#4FA686' },
  { label: 'Maybe', name: 'maybe', color: '#DFB940' },
  { label: 'Rejected', name: 'rejected', color: '#F46666' },
  { label: 'Pending', name: 'pending', color: '#B7BBC5' }
];
const iconMap = {
  Complete: iconCompleted,
  'In Progress': iconInProgress,
  Queued: iconQueued,
  Failed: iconFailed
};
const DiscoveryScanReportModule = (props: any) => {
  const [data, setData] = useState<ISettingsItems>(initData);
  const [panelIsOpen, setPanelIsOpen] = useState(true);
  const [headerData, setHeaderData] = useState<any>({});
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedGridRows, setSelectedGridRows] = useState<any>([]);
  const {
    saveCustomViewVisible,
    setSaveCustomViewVisible
  } = useDiscoveryScanReport() as DiscoveryScanReportContextType;
  const { columnPicker } = useSelector((state: IReduxState) => state.columnPicker);
  const { user } = useSelector((state: IReduxState) => state.login);
  const { showAddCommentPopupDiscoveryPending, rows } = useSelector((state: IReduxState) => state.grid);
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const params = queryString.parse(location.search);
  const { setIsComponentVisible } = useComponentVisible(false);

  const onTileClick = (params: any) => {
    console.log('XXX: ', params);
  };
  const fetchData = () => {
    setData(initData);
    // dispatch(removeAllRows());

    RestApi.getData(`discovery/dpbs/${user.company_name}/scans/?id=${params.rowId}`).subscribe((results: any) => {
      const scanData = results.results[0];
      if (!!scanData) {
        setHeaderData({
          type: scanData.discovery_type,
          scan_status: scanData.scan_status,
          start: scanData.scan_start_time,
          end: scanData.scan_end_time
        });
      }
    });
    const url = Common.AddMsspAccountIfNeeded('discovery/dpbs/scans/domains/assets/columns');
    RestApi.getData(url).subscribe((columns: IRootColumnPicker) => {
      let fields = ColumnsManage.checkGridColumnsToDisplay(columns.data);
      dispatch(setFields(fields));
      dispatch(setInitialPath(`discovery/dpbs/${user.company_name}/scans/domains/${params.discoveryScanId}/assets`));

      dispatch(setColumnPicker(columns.data));
      dispatch(setShowColumnPicker(columns.is_displayable));

      setData({
        rows: [],
        isMoreButton: columns.is_more_button,
        isCSVDownloadable: columns.is_csv_downloadable,
        count: 0,
        selectTab: '',
        identifier: columns.identifier_field,
        columns: ColumnsManage.createColumns(
          columns.identifier_field,
          columns.data || [],
          {
            page: 'settings',
            category: 'organization-management',
            tab: 'manage-fqdns'
          },
          {
            cb: onTileClick
          }
        ),
        rootPath: `discovery/dpbs/${user.company_name}/scans/domains/${params.discoveryScanId}/assets`
      });
    });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnSubmit = () => {
    console.log('Submit');
  };

  const handleOnClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    setAnchorEl(event.target as HTMLButtonElement);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemOnClick = (item: any) => (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
    const url = Common.AddMsspAccountIfNeeded('remediation/action-items/state/');

    if (item.label !== 'Pending') {
      const payload = {
        ids: [],
        state: item.name
      };
      RestApi.setData(url, payload, 'POST').subscribe((response: any) => {
        dispatch(updateRows({ rows: response, identifier: 'id' }));
        dispatch(showSuccess('Updated Successfully'));
      });
    } else {
      const statusOptions = columnPicker.find((col: any) => col.type === 'discovery_investigation_asset_verdict')
        .choices;
      const nextOption = statusOptions.find((opt: any) => opt.label === 'Pending');

      const listOptions = selectedGridRows?.reduce((all: any, id: any) => {
        const rowState = rows.find((row: any) => row.id === id).verdict;
        const option = !!rowState
          ? statusOptions.find((opt: any) => opt.label === rowState)
          : { label: 'Select', name: 'select', is_toggled: false };

        return {
          ...all,
          [id]: option
        };
      }, {});
      dispatch(
        setDiscoveryVerdictDropdown({
          listLastStatusOption: listOptions,
          nextStatusOption: nextOption
        })
      );

      const updatedRows = selectedGridRows?.reduce((all: any, id: any) => {
        const row = rows.find((row: any) => row.id === id);
        return [
          ...all,
          {
            ...row,
            verdict: 'Pending'
          }
        ];
      }, []);
      dispatch(updateRows({ rows: updatedRows, identifier: 'id' }));
      dispatch(setShowAddCommentPopupDiscoveryPending(true));
    }
    setIsComponentVisible(false);
  };

  const handlePopupVisible = (visible: boolean) => {
    dispatch(setShowAddCommentPopupDiscoveryPending(visible));
  };

  const open = Boolean(anchorEl);

  let timeHeader = '';
  if (!!Object.keys(headerData).length) {
    const time = new Intl.DateTimeFormat('en-US', optionsTime).format(
      new Date(headerData.end ? headerData.end : headerData.start)
    );
    const y = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(
      new Date(headerData.end ? headerData.end : headerData.start)
    );
    const m = new Intl.DateTimeFormat('en', { month: 'short' }).format(
      new Date(headerData.end ? headerData.end : headerData.start)
    );
    const d = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(
      new Date(headerData.end ? headerData.end : headerData.start)
    );
    timeHeader = `${m} ${d}, ${y} ${time}`;
  }
  return (
    <div className="DiscoveryScanReport">
      <div className="header">
        <Text textSize={16} weight={500} textColor="#5F6575">
          Investigation Tool
        </Text>
        {!!headerData && !!Object.keys(headerData).length && (
          <>
            <div style={{ backgroundColor: '#C5C8CE', width: 1, height: '100%', margin: '0 16px' }} />
            <Text textSize={16} weight={500} textColor="#5F6575">
              {headerData.type}
            </Text>
            <div style={{ backgroundColor: '#C5C8CE', width: 1, height: '100%', margin: '0 16px' }} />
            <div className="icon-date">
              <div style={{ marginRight: 12 }}>{iconMap[headerData.scan_status as keyof typeof iconMap]}</div>
              {!!timeHeader && (
                <Text textSize={16} textColor="#5F6575" weight={500}>
                  {timeHeader}
                </Text>
              )}
            </div>
          </>
        )}
        <div style={{ flexGrow: 1 }} />
        <div style={{ marginRight: 24 }}>
          <Button
            text="Submit"
            onClick={handleOnSubmit}
            type="button"
            buttonStyle="main"
            size={'small'}
            className="submit"
          />
        </div>
      </div>
      <div className="investigation-tool-content">
        <div className="grid-wrapper">
          {!!data.columns.length && (
            <div style={{ height: '100%', zIndex: 1, display: 'flex' }}>
              <div className={classNames('drawer-wrapper', { open: panelIsOpen }, { closed: !panelIsOpen })}>
                <InvestigationLeftPanel isOpen={panelIsOpen} setPanelIsOpen={setPanelIsOpen} />
              </div>
              <Grid
                columns={data.columns}
                rows={data.rows}
                count={data.count}
                path={data.rootPath}
                isMoreButton={data.isMoreButton}
                isCSVDownloadable={data.isCSVDownloadable}
                addedActions={(props: any) => {
                  setSelectedGridRows(props.ids);
                  return (
                    <Button
                      text="Action"
                      onClick={handleOnClick}
                      type="button"
                      buttonStyle="secondary"
                      size={'small'}
                      disabled={!props.ids.length}
                      className={classNames({ disabled: !props.ids.length })}
                    />
                  );
                }}
                identifier={data.identifier}
                initialState={{
                  pinnedColumns: { right: ['actions'] }
                }}
              />
            </div>
          )}
        </div>
      </div>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        className="discovery-investigation-action-dropdown"
        disableScrollLock={true}
      >
        <>
          <Text textSize={13} weight={500} style={{ padding: '12px 12px 8px 12px' }}>
            Status
          </Text>
          {options.map((option: any) => (
            <div key={option.name} className={classNames('option-item')} onClick={handleItemOnClick(option)}>
              <div
                style={{ width: 10, height: 10, borderRadius: '50%', backgroundColor: option.color, marginRight: 12 }}
              />
              <Text textColor="#5D6576" textSize={13}>
                {option.label}
              </Text>
            </div>
          ))}
        </>
      </Popover>

      <PopUp setVisible={handlePopupVisible} visible={showAddCommentPopupDiscoveryPending}>
        <AddDiscoveryCommentPopup setVisible={handlePopupVisible} />
      </PopUp>
      {saveCustomViewVisible && <SaveCustomView onClose={() => setSaveCustomViewVisible(false)} />}
    </div>
  );
};

const DiscoveryScanReport = () => {
  return (
    <DiscoveryScanReportContextProvider>
      <DiscoveryScanReportModule />
    </DiscoveryScanReportContextProvider>
  );
};

export default DiscoveryScanReport;
