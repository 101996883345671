import { useEffect, useState } from 'react';
import { Common } from '../../services/common.service';
import classNames from 'classnames';
import './PopSide.scss';

export function PopSide(props: any) {
  const [visible, setVisible] = useState<boolean>(false);

  // const onClose = () => {
  //   setVisible(false);
  //   setTimeout(props.onHide, 300);
  // };

  const onHide = () => {
    setVisible(false);
    setTimeout(props.onHide, 300);
  };

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 0);
    const El = document.getElementsByClassName('main-content-area')[0];
    El?.classList.add('ontop');
    Common.displayHelp(false);
    return () => {
      El?.classList.remove('ontop');
      Common.displayHelp(true);
    };
  }, []);

  return (
    <div
      className={classNames(props.className, 'PopSide', {
        visible: visible,
        hidden: !visible,
        'parent-container-popside': !!props.showSecondLevelPopSide
      })}
    >
      {!props.secondLevel && <div className="bg-layer" onClick={onHide}></div>}
      {props.secondLevel && <div className="bg-layer-ontop"></div>}
      <div
        className={classNames(
          classNames(
            'PopSide-inner',
            { 'parent-popside': !!props.showSecondLevelPopSide },
            { 'ai-width': props.isActionItems }
          ),
          {}
        )}
        style={{ width: `750px !important` }}
        // style={!!props.width ? { width: `${props.width}px !important` } : {}}
      >
        <img src="/assets/images/x.svg" alt="Close" className="close-trigger" onClick={onHide} />
        {props.children}
      </div>
    </div>
  );
}
