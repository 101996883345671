// @ts-ignore
import { query, QueryBuilder as QB } from 'js-query-builder';
import { env } from 'env';
import { FilterDateManage } from './components/gridUtilities/filtersWrapper/services/FilterDateManage';

class QueryBuilder {
  query: QB = query();

  constructor() {
    this.query = '';
  }

  buildQuery(params: any, globalFilter?: string) {
    const additional = !!params.additionalFields ? params.additionalFields : [];
    const _fields = Array.isArray(params.fields) ? params.fields : params.fields.split(',');
    this.addFields([..._fields, ...additional]);
    this.addFilters(params.filters);
    this.addSearch(params.search);
    this.addSorting(params.order);
    this.addGlobalFilter(!!globalFilter ? globalFilter : '');
  }

  setBaseURL(path: string, getFullPath = true) {
    if (getFullPath) {
      const baseUrl = env.REACT_APP_BASE_URL;
      this.query = query(`${baseUrl}${path}`);
    } else {
      this.query = query(path);
    }
  }

  addSearch(term: string) {
    if (term) {
      this.query.param('search', term);
    }
  }

  addFields(fields: any) {
    this.query.param('fields', fields);
  }

  addGlobalFilter(globalFilter: string) {
    if (globalFilter) {
      this.query.param('group', globalFilter);
    }
  }

  addSorting(order: string) {
    if (order) {
      this.query.param('ordering', order);
    }
  }

  addFilters(filters: any) {
    if (filters && Object.keys(filters).length) {
      for (let filter in filters) {
        if (filters[filter].type === '__index') {
          // Temporary to handle date filters with 'Last XXX Days'
          let date1offsetCalc: number = 0;
          switch (filters[filter].value) {
            case 0:
              date1offsetCalc = 1;
              break;
            case 1:
              date1offsetCalc = 7;
              break;
            case 2:
              date1offsetCalc = 30;
              break;
            default:
              break;
          }
          const date1 = FilterDateManage.createfromatDateToDB(FilterDateManage.calculateDaysBack(date1offsetCalc));
          const date2 = FilterDateManage.createfromatDateToDB(FilterDateManage.calculateDaysBack(-1));
          this.query.param(`${filter}__range`, `${date1},${date2}`);
        } else {
          // if (filters[filter].value === 'blank') {
          //   this.query.param(`${filter}__isnull`, 'True');
          // } else {
          if (filters[filter].type.includes('=True')) {
            this.query.param(`${filter}${filters[filter].type.replace('=True', '')}`, 'True');
          } else if (filters[filter].type.includes('=False')) {
            this.query.param(`${filter}${filters[filter].type.replace('=False', '')}`, 'False');
          } else {
            this.query.param(`${filter}${filters[filter].type}`, filters[filter].value);
          }

          // }
        }
      }
    }
  }

  getQuery() {
    return decodeURIComponent(this.query.build());
  }
}

export const queryBuilder = new QueryBuilder();
