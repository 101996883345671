import { LinkNewTab } from 'common/components/LinkNewTab/LinkNewTab';
import './TOSAgreement.scss';

export function TOSAgreement(props: any) {
  if (localStorage.getItem('tos_agreement')) {
    return null;
  }

  return (
    <div className="TOSAgreement">
      By continuing you agree to the IONIX
      <br />
      <LinkNewTab to="https://www.ionix.io/terms-and-conditions/" text="Terms and Conditions" />.
    </div>
  );
}
