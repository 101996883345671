
import { getUrgencyBySeverity } from '@cyberpion/cyberpion-ui';
import './ScoreRenderer.scss';

export function ScoreRenderer(props: any) {
  if (!props.value) {
    return null;
  }
  return (
    <div className="score-renderer">
        {getUrgencyBySeverity(props.value)['type']} 
    </div>
  );
}
