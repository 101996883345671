import './SubTabsLinks.scss';
import { Link } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { IReduxState } from 'app/store';
import { useSelector } from 'react-redux';

export function SubTabsLinks(props: any) {
  const flags = useFlags();

  const { defaultViews } = useSelector((state: IReduxState) => state.views);

  const getDefaultViewIfExists = (tab: any) => {
    try {
      return (
        (defaultViews['Assesments'] &&
          defaultViews['Assesments'][tab.gridPath as string] &&
          defaultViews['Assesments'][tab.gridPath as string].views[0]?.path) ||
        tab.link
      );
    } catch (err) {
      return tab.link;
    }
  };

  const getSubTabsHTML = (): JSX.Element[] => {
    let _html: JSX.Element[] = [];
    Object.keys(props.tabs).forEach((tab: string) => {
      if (!props.tabs[tab].featureFlag || flags[props.tabs[tab].featureFlag]) {
        _html.push(
          <li className={props.activeSubTab === props.tabs[tab].key ? 'active' : ''}>
            <span className="bottom-left"></span>
            <Link to={getDefaultViewIfExists(props.tabs[tab])}>{props.tabs[tab].title}</Link>
            <span className="bottom-right"></span>
          </li>
        );
      }
    });
    return _html;
  };

  return (
    <div className="SubTabsLinks">
      <ul>{getSubTabsHTML()}</ul>
    </div>
  );
}
