import { AppLoading } from 'common/components/appLoading/appLoading';
// import { RestApi } from 'common/services/rest-api.service';
import { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showSuccess } from 'features/AlertSlice';
import { Button, Text } from '@cyberpion/cyberpion-ui';
// import { Common } from 'common/services/common.service';
import { IReduxState } from 'app/store';
import { setDiscoveryVerdictDropdown, setShowAddCommentPopupDiscoveryPending, updateRows } from 'features/GridSlice';

import './AddDiscoveryCommentPopup.scss';

const AddDiscoveryCommentPopup = (props: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(true);
  const refTextArea = useRef<HTMLTextAreaElement>(null);
  const { discoveryVerdictDropdown, rows } = useSelector((state: IReduxState) => state.grid);
  const dispatch = useDispatch<any>();

  const onApply = () => {
    setLoading(true);
    // const url = Common.AddMsspAccountIfNeeded('remediation/action-items/state/');
    // const payload = {
    //   ids: Object.keys(discoveryVerdictDropdown.listLastStatusOption),
    //   state: discoveryVerdictDropdown.nextStatusOption?.name,
    //   comment: refTextArea.current?.value
    // };
    // RestApi.setData(url, payload, 'POST').subscribe((response: any) => {
    //   const updatedRows = Object.keys(discoveryVerdictDropdown.listLastStatusOption)?.reduce((all: any, id: any) => {
    //     const row = rows.find((row: any) => row.id === id);
    //     return [
    //       ...all,
    //       {
    //         ...row,
    //         user_comments_count: row.user_comments_count + 1,
    //         state_update_user: user.email,
    //         state_update_timestamp: new Date().toISOString()
    //       }
    //     ];
    //   }, []);
    //   dispatch(updateRows({ rows: updatedRows, identifier: 'id' }));
    //   dispatch(setShowAddCommentPopupDiscoveryPending(false));

    //   dispatch(
    //     setDiscoveryVerdictDropdown({
    //       listLastStatusOption: null,
    //       nextStatusOption: null
    //     })
    //   );
    //   setLoading(false);
    //   dispatch(showSuccess('Status Updated Successfully'));
    // });
    dispatch(
      setDiscoveryVerdictDropdown({
        listLastStatusOption: null,
        nextStatusOption: null
      })
    );
    setLoading(false);
    props.setVisible(false);
    dispatch(showSuccess('Status Updated Successfully'));
  };

  const handleCancelOnClick = () => {
    const updatedRows = Object.keys(discoveryVerdictDropdown.listLastStatusOption)?.reduce((all: any, id: any) => {
      const row = rows.find((row: any) => row.id === id);
      return [
        ...all,
        {
          ...row,
          verdict: discoveryVerdictDropdown.listLastStatusOption[id].label
        }
      ];
    }, []);
    dispatch(updateRows({ rows: updatedRows, identifier: 'id' }));
    props.setVisible(false);
  };

  const handleIsConfirmDisabled = () => {
    setIsConfirmDisabled(!refTextArea.current?.value);
  };

  const handleSkipOnClick = () => {
    const updatedRows = Object.keys(discoveryVerdictDropdown.listLastStatusOption)?.reduce((all: any, id: any) => {
      const row = rows.find((row: any) => row.id === id);
      return [
        ...all,
        {
          ...row,
          verdict: 'Pending'
        }
      ];
    }, []);
    dispatch(updateRows({ rows: updatedRows, identifier: 'id' }));
    dispatch(setShowAddCommentPopupDiscoveryPending(true));
    props.setVisible(false);
  };

  return (
    <div className="AddDiscoveryCommentPopup popup-inner-wrapper">
      {loading && <AppLoading />}
      <div className="popup-inner">
        <Text textSize={18} weight={500} style={{ marginBottom: 12 }}>
          Add Pending Comment
        </Text>
        <Text textSize={13} textColor="#8C909B" style={{ lineHeight: 1.35, marginBottom: 16 }}>
          Please provide Pending Comment
        </Text>

        <div className="text-area">
          <textarea
            ref={refTextArea}
            className="add-evidence-textarea"
            placeholder="Add Comment"
            onChange={handleIsConfirmDisabled}
          />
        </div>

        <div className="buttons-wrapper">
          <Button
            buttonStyle="secondary"
            disabled={false}
            type="button"
            size="small"
            onClick={handleSkipOnClick}
            text="Skip"
          />
          <div style={{ flexGrow: 1 }} />
          <Button
            buttonStyle="secondary"
            disabled={false}
            type="button"
            size="small"
            onClick={handleCancelOnClick}
            text="Cancel"
          />
          <Button
            buttonStyle="main"
            disabled={isConfirmDisabled}
            type="button"
            size="small"
            onClick={onApply}
            text="Submit"
          />
        </div>
      </div>
    </div>
  );
};

export default AddDiscoveryCommentPopup;
