import React, { useEffect, useState } from 'react';

import { Text } from '@cyberpion/cyberpion-ui';
import InvestigationRules from '../InvestigationRules/InvestigationRules';
import { Grow } from '@mui/material';
import InvestigationSaveViews from '../InvestigationSaveViews/InvestigationSaveViews';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { RestApi } from 'common/services/rest-api.service';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, IReduxState } from 'app/store';
import { getRows } from 'features/GridSlice';
import { DiscoveryScanReportContextType, useDiscoveryScanReport } from '../DiscoveryScanReportContext';
import './InvestigationLeftPanel.scss';

const dataViews = [
  {
    id: '1',
    rule: '2 same sld',
    approved: 10,
    maybe: 20000
  },
  {
    id: '2',
    rule: 'Redirects lead to keydomain and same sld of the candidate and the keydomains without tld',
    approved: 1111111,
    maybe: 30
  },
  {
    id: '3',
    rule: 'equal redirects favicons and certificate subject common name contain keydomains sld',
    approved: 30,
    maybe: 40
  },
  {
    id: '4',
    rule: 'Rule 4',
    approved: 40,
    maybe: 50
  },
  {
    id: '5',
    rule: 'Rule 5',
    approved: 50,
    maybe: 60
  }
];

const iconArrows = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M7.85156 11L5.35156 8.09973L7.85156 5" stroke="#5D6576" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M11 11L8.5 8.09973L11 5" stroke="#5D6576" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

const InvestigationLeftPanel = ({
  isOpen,
  setPanelIsOpen
}: {
  isOpen: boolean;
  setPanelIsOpen: (open: boolean) => void;
}) => {
  const [selectedTab, setSelectedTab] = useState('rules');
  const [rulesData, setRulesData] = useState([]);
  const { user } = useSelector((state: IReduxState) => state.login);
  const { investigation_rules, filters } = useSelector((state: any) => state.grid);
  const { setRulesCount } = useDiscoveryScanReport() as DiscoveryScanReportContextType;
  const location = useLocation();
  const params = queryString.parse(location.search);

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    const fetchRules = () => {
      // dispatch(removeAllRows());
      // const account = !!params.account ? `?account=${params.account}` : '';
      // const filters = !!params.filters ? (!!account ? `&filters=${params.filters}` : `?filters=${params.filters}`) : '';

      // const filters = !!params.filters ? `?filters=${params.filters}` : '';
      let f = '?';
      for (let key in filters) {
        if (f !== '?') {
          f += '&';
        }
        f += `${key}${filters[key].type}=${filters[key].value}`;
      }
      console.log('XXX: filters', f, filters);
      const url = `discovery/dpbs/${user.company_name}/scans/domains/${params.rowId}/rules/${f}`;
      // const url = `discovery/dpbs/${user.company_name}/scans/domains/${params.rowId}/rules/${account}${filters}`;
      RestApi.getData(url).subscribe((results: any) => {
        setRulesData(results);
        setRulesCount(results.length);
        // setFilteredData(results);
      });
    };

    fetchRules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  // useEffect(() => {
  //   console.log('XXX: panel', location);
  // }, [location]);

  useEffect(() => {
    // console.log('XXX: investigation_rules', investigation_rules);
    dispatch(getRows());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investigation_rules]);

  const handleTabOnClick = (tab: string) => () => {
    setSelectedTab(tab);
    setPanelIsOpen(true);
  };

  const handleArrowIconOnClick = () => {
    setPanelIsOpen(!isOpen);
  };

  return (
    <div className="InvestigationLeftPanel">
      {isOpen && (
        <>
          <div className="panel-header-open">
            <div className="investigation-panel-tabs-wrapper">
              <div className="investigation-panel-tabs">
                <div onClick={() => setSelectedTab('rules')}>
                  <Text textSize={14} weight={500} textColor={selectedTab === 'rules' ? '#3455DD' : '#8C909A'}>
                    {`Rules ${!!rulesData.length ? '(' + rulesData.length + ')' : ''}`}
                  </Text>
                </div>
                <div onClick={() => setSelectedTab('saves')}>
                  <Text
                    textSize={14}
                    weight={500}
                    textColor={selectedTab === 'saves' ? '#3455DD' : '#8C909A'}
                    style={{ marginLeft: 12 }}
                  >
                    Save Views
                  </Text>
                </div>
              </div>
              <div style={{ flexGrow: 1 }}></div>
              <div className="arrows-icon" onClick={handleArrowIconOnClick} style={{ marginRight: 0 }}>
                {iconArrows}
              </div>
            </div>
          </div>
          <Grow in={isOpen} timeout={800}>
            <div className="investigation-panel-tab-content">
              {selectedTab === 'rules' && <InvestigationRules data={rulesData} />}
              {selectedTab === 'saves' && <InvestigationSaveViews data={dataViews} />}
            </div>
          </Grow>
        </>
      )}
      {!isOpen && (
        <div className="panel-header-closed">
          <div className="arrows-icon" onClick={handleArrowIconOnClick} style={{ marginRight: 16 }}>
            {iconArrows}
          </div>
          <div className="investigation-panel-tabs">
            <div onClick={handleTabOnClick('rules')}>
              <Text textSize={14} weight={500} textColor={selectedTab === 'rules' ? '#3455DD' : '#8C909A'}>
                {`Rules ${!!rulesData.length ? '(' + rulesData.length + ')' : ''}`}
              </Text>
            </div>
            <div onClick={handleTabOnClick('saves')}>
              <Text
                textSize={14}
                weight={500}
                textColor={selectedTab === 'saves' ? '#3455DD' : '#8C909A'}
                style={{ marginLeft: 12 }}
              >
                Save Views
              </Text>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InvestigationLeftPanel;
