import './booleanRenderer.scss';

export function BooleanRenderer(props: any) {
  //@param: value = boolean
  //@param: trueString (optional) = string to replace "True"
  //@param: falseString (optional) = string to replace "False"
  //@return: Capitalize the first letter
  const capitalizeFirstLetter = (value: boolean) => {
    try {
      let str = value.toString();
      return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
    } catch (error) {
      return 'False';
    }
  };
  // Determine the display string based on trueString and falseString parameters
  const renderValue = props.value
    ? props.trueString || capitalizeFirstLetter(props.value)
    : props.falseString || capitalizeFirstLetter(props.value);


  return <div className="BooleanRenderer-scope">{renderValue}</div>;
}
