type TFilterType = 'multiple_choice' | 'radio' | 'stringAndNumber' | 'date';

export const FILTER_TYPE = {
  MULTIPLE_CHOICE: 'multiple_choice',
  RADIO: 'radio',
  FREE_TEXT: 'free_text',
  DATE: 'date'
};

export const getFiltersServerFormat = (
  type: TFilterType,
  choiceItem: any,
  values: any,
  toggledOption?: any,
  isFilterOut?: boolean
) => {
  if (type === 'multiple_choice') {
    const filterType = [
      'tags',
      'array_number',
      'array_string',
      'rbac_groups',
      'array_chips',
      'count_info_panel'
    ].includes(choiceItem.type)
      ? 'overlap'
      : 'in';

    if (values[0] === 'blank') {
      return {
        [choiceItem.name]: {
          type: `__isnull`,
          value: isFilterOut ? 'False' : 'True'
        }
      };
    }
    return {
      [choiceItem.name]: {
        type: `__${filterType}${isFilterOut ? '_ne' : ''}`,
        value: values.join(',')
      }
    };
  }
  if (type === 'radio') {
    return {
      [choiceItem.name]: {
        type: '',
        value: values.name
      }
    };
  }
  if (type === 'stringAndNumber') {
    let filterType = choiceItem.type === 'array_number' ? '__overlap' : values.name;

    if (choiceItem.type === 'array_number' && values.name === '__overlap_ne') {
      filterType = values.name;
    }

    if (values.name.includes('isnull')) {
      return {
        [choiceItem.name]: {
          type: `__isnull`,
          value: values.name.replace('__isnull=', '')
        }
      };
    }
    return {
      [choiceItem.name]: {
        type: filterType,
        value: values.value.trim()
      }
    };
  }
  if (type === 'date') {
    let filter = {
      [choiceItem.name]: {
        type: toggledOption.name,
        value: values
      }
    };
    if (toggledOption.label.startsWith('Last')) {
      filter[choiceItem.name].type = '__index';
      filter[choiceItem.name].value = toggledOption.id;
    }
    return filter;
  }
};
