import React from 'react';
import classNames from 'classnames';
import { Common } from 'common/services/common.service';
import './OpenNewTabAction.scss';

const icon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path d="M7.52499 2.5H10.075V5.05" stroke="#5D6576" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10.075 2.5L6.24998 6.325" stroke="#5D6576" stroke-linecap="round" stroke-linejoin="round" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.82499 2H3.42499C2.59656 2 1.92499 2.67157 1.92499 3.5V9.15C1.92499 9.97843 2.59656 10.65 3.42499 10.65H9.07499C9.90342 10.65 10.575 9.97843 10.575 9.15V6.75H9.57499V9.15C9.57499 9.42614 9.35113 9.65 9.07499 9.65H3.42499C3.14885 9.65 2.92499 9.42614 2.92499 9.15V3.5C2.92499 3.22386 3.14885 3 3.42499 3H5.82499V2Z"
      fill="#5D6576"
    />
  </svg>
);

const OpenNewTabAction = (props: any) => {
  const handleOnClick = () => {
    const path = Common.AddMsspAccountIfNeeded(
      `/discovery-investigation-tool/?rowId=${props.row.id}&discoveryScanId=${props.row.scan_id}`
    );
    window.open(`${path}`, '_blank');
  };
  // Todo: fix disabled className according to BE data
  return (
    <div
      className={classNames('OpenNewTabAction', { disabled: props?.row.source === 'Discovery1' })}
      onClick={handleOnClick}
    >
      {icon}
    </div>
  );
};

export default OpenNewTabAction;
