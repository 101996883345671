import { useHistory } from 'react-router-dom';
import { IOption, ISidebarMenu } from './interface';
import { Button } from '@mui/material';
import { AppDispatch, IReduxState } from 'app/store';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { LoginService } from 'login/services/login.service';
import { Common } from 'common/services/common.service';
import { useFlags } from 'launchdarkly-react-client-sdk';

import './sidebar-menu.scss';
import { selectView } from 'features/ViewsSlice';

interface IMenu {
  pageName: string;
  data: ISidebarMenu;
}
export function SidebarMenu(props: IMenu) {
  const history = useHistory();
  const { user } = useSelector((state: IReduxState) => state.login);
  const { defaultViews } = useSelector((state: IReduxState) => state.views);
  const flags = useFlags();
  const dispatch: AppDispatch = useDispatch();

  const isActive = (item: IOption) => {
    // TODO - replace this hack by adding the singleAsset under the discovery path
    if (props.pageName === 'singleAsset' && item.key === 'discovery') {
      return true;
    }
    return item.key === props.pageName;
  };

  const onClickButton = (
    event: React.MouseEvent<HTMLElement>,
    link: string,
    memberLink?: string,
    msspLink?: string
  ) => {
    if (!event.metaKey && !event.ctrlKey) {
      if (user.is_mssp_user && !Common.getParamFromUrl('account') && msspLink) {
        history.push(msspLink);
      } else if (LoginService.isMember(user) && memberLink) {
        history.push(memberLink);
      } else {
        history.push(link);
      }
      event.preventDefault();
    }
  };

  const filter = (item: IOption) => {
    if (item.restricted || (!!item.flag && !flags[item.flag])) {
      return false;
    }

    if (user.is_mssp_user && !Common.getParamFromUrl('account')) {
      if (item.hideForMssp) {
        return false;
      } else {
        return true;
      }
    } else if (item.showOnlyForMssp) {
      return false;
    } else {
      return true;
    }
  };

  const getLink = (item: IOption) => {
    const defaultView = getDefaultViewIfExists(item);

    if (defaultView) {
      return Common.getDecodedViewPath(defaultView.path);
    }

    if (user.is_mssp_user && !Common.getParamFromUrl('account') && item.msspLink) {
      return item.msspLink;
    }

    return LoginService.isMember(user) && item.memberLink ? item.memberLink : item.link;
  };

  const getDefaultViewIfExists = (item: IOption) => {
    const title = item.title.replace('Assessments', 'Assesments');
    return (
      defaultViews[title] &&
      defaultViews[title][item.defaultTab as string] &&
      defaultViews[title][item.defaultTab as string].views[0]
    );
  };

  return (
    <div className="menu-sidebar">
      {Object.values(props.data)
        .filter(filter)
        .map((item: IOption, index: number) => (
          <div className="item-sidebar" key={index}>
            <Button
              component={Link}
              to={getLink(item)}
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                const defaultView = getDefaultViewIfExists(item);
                if (defaultView) {
                  e.preventDefault();
                  history.push(Common.getDecodedViewPath(defaultView.path));
                  dispatch(selectView(defaultView.id));
                } else {
                  dispatch(selectView(null));
                  onClickButton(e, item.link, item.memberLink, item.msspLink);
                }
              }}
              data-testid={item.key}
            >
              <item.IconComponent active={isActive(item)} />
              <div className={isActive(item) ? 'link-page-title selected' : 'link-page-title uselected'}>
                {item.title}
              </div>
            </Button>
          </div>
        ))}
    </div>
  );
}
